<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs>
      <b-breadcrumb-item :text="`Management - ${$store.state.settings.app.current.title}`"/>
      <b-breadcrumb-item text="Invoices" :to="{ name: 'management-invoices' }" />
      <b-breadcrumb-item v-if="invoice" :text="`${invoice.student.name.first} ${invoice.student.name.last}`" active />
    </template>
    <template #actions="{ state }">
      <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateInvoice">
        <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
      </b-button>
      <b-button v-if="!state.editing" v-b-tooltip="'Print'"
                variant="transparent" size="sm"
                :to="{ name: 'management-invoice-print', params: { id, invoiceToSchool, invoiceToSchoolLegal } }">
        <font-awesome-icon icon="fa-solid fa-print" />
      </b-button>
    </template>
    <template #dropdown-options="{ state }">
      <b-dropdown-item @click="state.editing = !state.editing">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
      <can do="delete" on="management-invoice">
        <b-dropdown-item @click="$refs.layout.confirmDelete(invoice, deleteInvoice, cascadeConfirmDeleteOptions)">
          <feather-icon icon="Trash2Icon"/>
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>
      <b-dropdown-divider/>
      <b-dropdown-group header="Invoice To">
        <b-dropdown-item @click.native.capture.stop="invoiceToSchool = !invoiceToSchool">
          <font-awesome-icon :icon="`fa-solid fa-${invoiceToSchool ? 'toggle-on' : 'toggle-off'}`"/>
          <span class="align-middle ml-50">School</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="invoiceToSchool" @click.native.capture.stop="invoiceToSchoolLegal = !invoiceToSchoolLegal">
          <font-awesome-icon :icon="`fa-solid fa-${invoiceToSchoolLegal ? 'toggle-on' : 'toggle-off'}`"/>
          <span class="align-middle ml-50">Legal Name</span>
        </b-dropdown-item>
      </b-dropdown-group>
      <b-dropdown-divider/>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>


    <template #content="{ state }">
      <template v-if="error">
        <b-alert id="student-error" show variant="danger">
          <p>
            <b-icon-exclamation-circle class="mr-25"/>
            {{ error }}
          </p>
        </b-alert>
      </template>
      <template v-else>
        <validation-observer v-if="invoice" ref="observer" tag="div">
          <section :class="{'invoice-add-wrapper': state.editing, 'invoice-preview-wrapper': !state.editing}">
            <b-row :class="{'invoice-add': state.editing, 'invoice-preview': !state.editing}">
              <b-col cols="12">
                <b-form @submit.prevent>
                  <b-card no-body class="invoice-preview-card font-small-3">
                    <!-- Invoice Header -->
                    <template>
                      <b-card-body class="invoice-padding pb-0">
                        <b-row class="justify-content-between invoice-spacing mt-0">

                          <!-- Header: Left Content -->
                          <b-col cols="6" class="px-0">
                            <invoice-header-left />
                          </b-col>

                          <!-- Header: Right Content -->
                          <template>
                            <b-col cols="6" class="px-0 d-flex justify-content-end">
                              <div class="invoice-details-wrapper mt-md-0 mt-2">
                                <div class="invoice-date-wrapper align-items-start">
                                  <span class="invoice-date-title">Invoice #</span>
                                  <span class="invoice-date text-right">{{ invoice.number }}</span>
                                </div>
                                <div class="invoice-date-wrapper">
                                  <span class="invoice-date-title">Date Issued:</span>
                                  <span class="invoice-date">{{ invoice.createdAt | date }}</span>
                                </div>
                                <div class="invoice-date-wrapper">
                                  <span class="invoice-date-title">Date Modified:</span>
                                  <span class="invoice-date">{{ invoice.updatedAt | date }}</span>
                                </div>
                              </div>
                            </b-col>

                          </template>
                        </b-row>
                      </b-card-body>
                      <hr class="invoice-spacing">
                    </template>

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body v-if="invoice.student" class="invoice-padding pt-0">
                      <!-- Invoice Details -->
                      <template>
                        <b-row class="invoice-spacing">
                          <!-- Invoice To -->
                          <b-col cols="12" xl="6" class="p-0 ">
                            <div class="invoice-details-wrapper">
                              <template v-if="invoiceToSchool">
                                <invoice-to heading="Invoice To:"
                                            :name="invoiceToSchoolLegal ?
                                            (hasValue(invoice, 'student.school.name.legal') ? invoice.student.school.name.legal : null)
                                            : (hasValue(invoice, 'student.school.name.popular') ? invoice.student.school.name.popular : null)"
                                            :address="hasValue(invoice, 'student.school.address') ? invoice.student.school.address : null"/>
                                <hr class="my-2"/>
                                <invoice-to heading="Invoice For:"
                                            :name="`${invoice.student.name.first} ${invoice.student.name.last}`"/>
                              </template>
                              <template v-else>
                                <invoice-to heading="Invoice To:"
                                            :name="`${invoice.student.name.first} ${invoice.student.name.last}`"
                                            :address="invoice.student.address"
                                            :phone="invoice.student.phone"
                                            :email="invoice.student.email"/>
                              </template>
                            </div>
                          </b-col>
                          <!-- Payment Details -->
                          <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">

                            <div class="invoice-total-wrapper">
                              <h6 class="mb-1">
                                Payment Details:
                              </h6>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Registration Fee:</p>
                                <p class="invoice-total-amount font-weight-normal">{{ setting.fees.nyssma | currency }}</p>
                              </div>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Room and Board:</p>
                                <p class="invoice-total-amount font-weight-normal">{{ setting.fees.hotel | currency }}</p>
                              </div>
                              <hr>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Total Due:</p>
                                <p class="invoice-total-amount font-weight-normal">{{ totalCost | currency }}</p>
                              </div>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Paid:</p>
                                <p class="invoice-total-amount font-weight-normal">{{ totalPaid | currency }}</p>
                              </div>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Pending:</p>
                                <p class="invoice-total-amount font-weight-normal">{{ totalPending | currency }}</p>
                              </div>
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Returns:</p>
                                <p class="invoice-total-amount font-weight-normal">
                                  {{ totalReturns > 0 ? '-' : null }}{{ totalReturns | currency }}
                                </p>
                              </div>
                              <hr class="my-50">
                              <div class="invoice-total-item">
                                <p class="invoice-total-title">Remaining Due:</p>
                                <p class="invoice-total-amount">{{ totalDue | currency }}</p>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </template>
                    </b-card-body>

                    <!-- Applications -->
                    <template>
                      <hr class="invoice-spacing">
                      <b-card-body class="invoice-padding d-flex justify-content-between align-items-center">
                        <div>
                          <b-card-title class="mb-25">Selected Applications</b-card-title>
                          <b-card-text class="font-small-3 d-print-none">Applications which have been selected for an ensemble. Includes non-accepted selections.</b-card-text>
                        </div>
                        <div class="d-print-none">
                          <b-badge v-if="shouldRefund" id="refund" variant="danger">Refund</b-badge>
                          <b-popover v-if="shouldRefund" target="refund" triggers="hover" placement="top" variant="danger">
                            <div class="d-flex align-items-center">
                              <b-icon-arrow-left-circle-fill size="1.25rem" class="mr-25"></b-icon-arrow-left-circle-fill>
                              <strong>Should Refund</strong>
                            </div>
                            <p class="mb-0">
                              This student has not accepted a selection, and has payments that have not been refunded.
                            </p>
                          </b-popover>
                        </div>
                      </b-card-body>
                      <b-table responsive="md"
                               :items="invoice.student.applications.items"
                               :fields="[
                                  { key: 'instrument.name', label: 'Instrument' },
                                  { key: 'selection.ensemble.name', label: 'Ensemble' },
                                  { key: 'selection.part', label: 'Part' },
                                  { key: 'selection.accepted', label: 'Accepted' }
                                ]"
                               show-empty empty-text="No Applications have been Selected for this Student">
                        <template #cell(instrument.name)="data">
                          <span v-if="hasValue(data, 'item.instrument')">{{ data.item.instrument.name }}</span>
                          <span v-else class="text-danger">No Instrument</span>
                        </template>
                        <template #cell(selection.ensemble.name)="data">
                          <span v-if="hasValue(data, 'item.selection.ensemble')">{{ data.item.selection.ensemble.name }}</span>
                          <span v-else class="text-danger">No Ensemble</span>
                        </template>
                        <template #cell(selection.accepted)="data">
                          {{ getAcceptedText(data.item.selection) }}
                        </template>
                      </b-table>
                    </template>

                    <!-- Payments Header -->
                    <hr class="invoice-spacing">
                    <b-card-body class="invoice-padding d-flex justify-content-between align-items-center">
                      <div>
                        <b-card-title class="mb-25">Payments</b-card-title>
                        <b-card-text class="font-small-3 d-print-none">
                          Payments which have been received and have not been returned are considered paid.
                          Payments without a received date are considered pending.
                        </b-card-text>
                      </div>
                      <div class="payment-status">
                        <b-badge v-if="hasOverpaid" id="overpaid" variant="danger" class="d-print-none">Overpaid</b-badge>
                        <b-popover v-if="hasOverpaid" target="overpaid" triggers="hover" placement="top" variant="danger">
                          <div class="d-flex align-items-center">
                            <b-icon-arrow-up-circle-fill size="1.25rem" class="mr-25"></b-icon-arrow-up-circle-fill>
                            <strong>Overpaid</strong>
                          </div>
                          <p class="mb-0">
                            The total number of received payments exceeds the total cost of the invoice.
                          </p>
                        </b-popover>

                        <b-badge v-if="hasUnderpaid" id="underpaid" variant="secondary" class="d-print-none">Underpaid</b-badge>
                        <b-popover v-if="hasUnderpaid" target="underpaid" triggers="hover" placement="top" variant="secondary">
                          <div class="d-flex align-items-center">
                            <b-icon-arrow-down-circle-fill size="1.25rem" class="mr-25"></b-icon-arrow-down-circle-fill>
                            <strong>Underpaid</strong>
                          </div>
                          <p class="mb-0">
                            The total number of received payments is less than total cost of the invoice.
                          </p>
                        </b-popover>

                        <b-badge v-if="isPaid" id="paid" variant="success" class="d-print-none">Paid</b-badge>
                        <b-popover v-if="isPaid" target="paid" triggers="hover" placement="top" variant="success">
                          <div class="d-flex align-items-center">
                            <b-icon-check-circle-fill size="1.25rem" class="mr-25"></b-icon-check-circle-fill>
                            <strong>Paid</strong>
                          </div>
                          <p class="mb-0">
                            The total number of received payments equals the total cost of the invoice.
                          </p>
                        </b-popover>

                        <b-badge v-if="hasPendingPayments" id="pending" variant="primary" class="ml-50 d-print-none">Pending</b-badge>
                        <b-popover v-if="hasPendingPayments" target="pending" triggers="hover" placement="top" variant="primary">
                          <div class="d-flex align-items-center">
                            <b-icon-arrow-right-circle-fill size="1.25rem" class="mr-25"></b-icon-arrow-right-circle-fill>
                            <strong>Pending Payments</strong>
                          </div>
                          <p class="mb-0">
                            Some payments included in this invoice have not been received.
                          </p>
                        </b-popover>
                      </div>
                    </b-card-body>

                    <!-- Payments Table -->
                    <template v-if="state.editing">
                      <b-table-simple responsive="md">
                        <b-thead>
                          <b-tr>
                            <b-th>Type</b-th>
                            <b-th>Check Number</b-th>
                            <b-th>Amount</b-th>
                            <b-th>Received</b-th>
                            <b-th>Returned</b-th>
                            <b-th class="text-right px-1">
                              <b-button v-b-tooltip="'New Payment'" v-b-modal="'invoice-payment-modal'" size="sm" variant="link" class="btn-ico">
                                <feather-icon icon="PlusIcon" />
                              </b-button>

                              <invoice-payment-modal id="invoice-payment-modal" :invoice-id="invoice.id" @created="addPayment" />
                            </b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <invoice-payment-row v-for="(payment, index) in invoice.payments.items" :key="index" :payment="payment" :index="index">
                            <template #dropdown-items>
                              <b-dropdown-item @click="$refs.layout.confirmDelete(payment.id, deletePayment, { confirmed: { text: 'Payment was successfully deleted.' }})">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Delete</span>
                              </b-dropdown-item>
                            </template>
                          </invoice-payment-row>
                        </b-tbody>
                      </b-table-simple>
                    </template>
                    <template v-else>
                      <b-table responsive="md"
                               :items="invoice.payments.items"
                               :fields="[ { key: 'type' }, { key: 'check', label: 'Check Number' }, { key: 'amount' }, { key: 'received' }, { key: 'returned' } ]"
                               show-empty empty-text="No Payments or Purchase Orders have been associated to this invoice.">
                        <template #cell(type)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.type }}
                          </b-card-text>
                        </template>
                        <template #cell(amount)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.amount | currency }}
                          </b-card-text>
                        </template>
                        <template #cell(received)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.received | date }}
                          </b-card-text>
                        </template>
                        <template #cell(returned)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.returned | date }}
                          </b-card-text>
                        </template>
                      </b-table>
                    </template>

                    <!-- Note -->
                    <template>
                      <div :class="invoice.memo ? '' : 'd-print-none'">
                        <hr class="invoice-spacing">
                        <b-card-body class="invoice-padding pt-0">
                          <span class="font-weight-bold">Memo: </span>
                          <span v-if="!state.editing">{{ invoice.memo }}</span>
                          <b-textarea v-else
                                      v-model="invoice.memo"
                                      :debounce="5000"
                                      @update="patchInvoice({ id: invoice.id, memo: $event})" />
                        </b-card-body>
                      </div>
                    </template>
                  </b-card>
                </b-form>
              </b-col>

            </b-row>

          </section>
        </validation-observer>
      </template>
    </template>
    <template v-if="invoice" #debug>
      <b-row>
        <b-col cols="6">
          <debug title="Setting" collapsed>{{ setting.value }}</debug>
        </b-col>
        <b-col cols="6">
          <debug title="Invoice" collapsed>{{ invoice }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin'
import status from '@/mixins/status.mixin'
import role from '@/mixins/role.mixin';
import print from '@/mixins/print.mixin';
import Logo from '@core/layouts/components/Logo.vue'
import PaymentAmount from '@/components/PaymentAmount.vue';
import InvoiceHeaderLeft from './InvoiceHeaderLeft.vue';
import InvoiceTo from '@/views/management/billing/InvoiceTo.vue';
import InvoicePaymentRow from './InvoicePaymentRow.vue';
import InvoicePaymentModal from './InvoicePaymentModal.vue';
import billing from './billing.mixin';
import flatPickr from 'vue-flatpickr-component';
import {API, graphqlOperation} from 'aws-amplify';
import {
  deletePayment,
  getInvoice, getPayment,
  getSetting, onCreatePayment,
  onDeletePayment,
  onUpdatePayment,
  updateInvoice,
  updatePayment
} from './invoice';
import { cascadeDeleteInvoice, cascadeConfirmDeleteOptions} from '@/graphql/cascade/invoice';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  components: {
    PageLayout,
    InvoiceHeaderLeft,
    InvoiceTo,
    InvoicePaymentModal,
    InvoicePaymentRow,
    PaymentAmount,
    vSelect,
    Logo,
    flatPickr
  },
  mixins: [avatar, role, status, print, notify, billing, lodashMixin, settingsMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      setting: {
        fees: {
          nyssma: 0,
          hotel: 0
        }
      },
      invoice: null,
      error: null,
      subscriptions: {
        onCreate: null,
        onUpdate: null,
        onDelete: null
      },
      rules: {
        money: { required: true, numeric: true, min_value: 1}
      },
      icon: 'fa-file-invoice-dollar',
      invoiceToSchool: true,
      invoiceToSchoolLegal: true,
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    payments() {
      return this.invoice?.payments.items || []
    },
    totalCost() {
      return this.setting.fees.nyssma + this.setting.fees.hotel
    },
    totalPaid() {
      let paid = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => payment.received && !payment.returned).forEach(payment => {
          paid += payment.amount
        })
      }
      return paid
    },
    totalPending() {
      let pending = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => !payment.received && payment.returned === null).forEach(payment => {
          pending += payment.amount
        })
      }
      return pending
    },
    totalReturns() {
      let returns = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => payment.returned).forEach(payment => {
          returns += payment.amount
        })
      }
      return returns
    },
    totalDue() {
      return this.totalCost - this.totalPaid
    },
    hasOverpaid() {
      if(this.invoice) {
        if(this.totalPaid > this.totalCost) {
          return true
        }
      }
      return false
    },
    hasUnderpaid() {
      if(this.invoice) {
        if(this.totalPaid < this.totalCost) {
          return true
        }
      }
      return false
    },
    isPaid() {
      if(this.invoice) {
        if(this.totalPaid === this.totalCost) {
          return true
        }
      }
      return false
    },
    hasPendingPayments() {
      if(this.invoice) {
        if(this.invoice.payments.items.some(payment => !payment.received && payment.returned === null)) {
          return true
        }
      }
      return false
    },
    shouldRefund() {
      if(this.invoice) {
        if(this.invoice?.student?.applications.items.every(app => app.selection?.accepted !== true)) {
          if(this.invoice.payments.items.length > 0 && this.invoice.payments.items.every(payment => !payment?.returned) && this.totalPaid > 0) {
            return true
          }
        }
      }
      return false
    }
  },
  async created() {
    await this.getSetting();
    await this.getInvoice();
    /*await this.onCreatePayment()
    await this.onUpdatePayment()
    await this.onDeletePayment()*/
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  beforeDestroy() {
    if(this.subscriptions?.onCreate) {
      this.subscriptions.onCreate.unsubscribe()
    }
    if(this.subscriptions?.onUpdate) {
      this.subscriptions.onUpdate.unsubscribe()
    }
    if(this.subscriptions?.onDelete) {
      this.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    /** Settings **/
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'billing' }));
      this.setting = response.data.getSetting
      if(this.setting) {
        this.setting = JSON.parse(this.setting.value)
      }
      this.loading = false
    },

    /** Invoice **/
    async getInvoice() {
      const response = await API.graphql(graphqlOperation(getInvoice, { id: this.id }));
      this.invoice = response.data.getInvoice
      if(this.invoice) {
        /** Check Current Year **/
        if(!this.isCreatedAtInCurrentYear(this.invoice.createdAt)) {
          this.error = `You are currently viewing ${this.$store.state.settings.app.current.title} data. This invoice was created in ${new Date(this.invoice.createdAt).getFullYear()}. Switch to that year to view this record.`
        }
      }
      else {
        this.error = 'Invoice not found.'
      }
    },
    async updateInvoice() {
      const input = {
        id: this.invoice.id,
        number: this.invoice.number,
        amount: this.invoice.amount,
        memo: this.invoice.memo,
        duplicates: this.invoice.duplicates,
        dueAt: this.invoice.dueAt
      }
      await this.patchInvoice(input)
    },
    async patchInvoice(input) {
      try {
        await API.graphql(graphqlOperation(updateInvoice, { input: input }));
        this.notify({ title: 'Success', text: 'Invoice was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Invoice failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteInvoice(invoice, swalCallback) {
      await this.cascadeDeleteInvoice(invoice.id, swalCallback)
      await this.notify({ title: 'Success', text: 'Invoice was successfully deleted', icon: this.icon, variant: 'success' });
      await this.$router.push({ name: 'management-invoices' })
    },
    cascadeDeleteInvoice,

    /** Payments **/
    addPayment(payment) {
      this.invoice.payments.items.push(payment)
    },
    removePayment(id) {
      this.invoice.payments.items = this.invoice.payments.items.filter(payment => payment.id !== id)
    },
    async deletePayment(id) {
      try {
        await API.graphql(graphqlOperation(deletePayment, { input: { id: id } } ));
        this.removePayment(id)
        this.notify({ title: 'Success', text: 'Payment was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
      }
    },
    async onCreatePayment() {
      this.subscriptions.onCreate = API.graphql(graphqlOperation(onCreatePayment)).subscribe(async (sourceData) => {
        const createdPayment = sourceData.value.data.onCreatePayment
        if (createdPayment && createdPayment.invoicePaymentsId === this.invoice.id && !this.invoice.payments.items.map(item => item.id).includes(createdPayment.id)) {
          this.syncNotification()
          const response = await API.graphql(graphqlOperation(getPayment, {id: createdPayment.id}));
          if (response) {
            this.invoice.payments.items.push(response.data?.getPayment)
          }
        }
      });
    },
    async onUpdatePayment() {
      this.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdatePayment)).subscribe(async (sourceData) => {
        const updatedPayment = sourceData.value.data.onUpdatePayment
        if (updatedPayment && this.invoice.payments.items.map(item => item.id).includes(updatedPayment.id)) {
          const index = this.invoice.payments.items.findIndex(item => item.id === updatedPayment.id)
          if (index > -1) {
            this.syncNotification()
            const response = await API.graphql(graphqlOperation(getPayment, {id: updatedPayment.id}));
            if(response) {
              this.invoice.payments.items.splice(index, 1, response.data?.getPayment)
            }
          }
        }
      });
    },
    async onDeletePayment() {
      this.subscriptions.onDelete = API.graphql(graphqlOperation(onDeletePayment)).subscribe((sourceData) => {
        const payment = sourceData.value.data.onDeletePayment
        if(payment && this.invoice.payments.items.map(item => item.id).includes(payment.id)) {
          this.syncNotification()
          this.invoice.payments.items = this.invoice.payments.items.filter(item => item.id !== payment.id);
        }
      });
    },

    /** UI **/
    async refresh() {
      this.$refs.layout.state.loading = true
      this.error = null
      await this.getInvoice()
      this.$refs.layout.state.loading = false
    },
    onDateChange(event) {
      const selectedDate = event[0]
      if(selectedDate) {
        this.patchInvoice({ id: this.invoice.id, dueAt: selectedDate.toISOString() })
      }
      else {
        this.patchInvoice({ id: this.invoice.id, dueAt: null })
      }
    },
    getAcceptedVariant(selection) {
      if(!selection) return 'dark'
      return selection.accepted ? 'success' : 'danger'
    },
    getAcceptedText(selection) {
      if(!selection) return 'No Selection'
      if(selection.accepted === null) return 'Undecided'
      return selection.accepted ? 'Yes' : 'No'
    },
    windowPrint() {
      window.print()
    },
    getValidationState({dirty, validated, valid = null}) {
      if (this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  }
}

</script>


<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 12px;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  a:link { text-decoration: none; color: #6e6b7b; }
  a:visited { text-decoration: none; color: #6e6b7b; }
  a:hover { text-decoration: none; color: #6e6b7b; }
  a:active { text-decoration: none; color: #6e6b7b; }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.invoice-total-wrapper {
  width: 100%;
  max-width: 25rem!important;
}

.invoice-details-wrapper {
  width: 100%;
  max-width: 25rem!important;
}


.invoice-preview .invoice-date-wrapper .invoice-date-title,
.invoice-edit .invoice-date-wrapper .invoice-date-title,
.invoice-add .invoice-date-wrapper .invoice-date-title {
  width: unset;
}

.invoice-date-wrapper {
  justify-content: space-between;
}

.invoice-date {
  margin: 0 !important;
}

.input-group-prepend .input-group-text {
  background-color: #efefef;
}

.flatpickr-input[disabled] {
  background-color: #efefef!important;
}

.form-control[readonly] {
  background-color: #fff;
}

</style>

