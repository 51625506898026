export default {
    methods: {
        statusVariantAndIcon(status) {
            if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
            if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
            if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
            if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
            if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
            if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
            return { variant: 'secondary', icon: 'XIcon' }
        },
        statusAvatarVariant(status) {
            if (status === 'Partial Payment') return 'primary'
            if (status === 'Paid') return 'danger'
            if (status === 'Downloaded') return 'secondary'
            if (status === 'Draft') return 'warning'
            if (status === 'Sent') return 'info'
            if (status === 'Past Due') return 'success'
            return 'primary'
        }
    }
}
