export default {
    methods: {
        roleVariant(role) {
            if (role === 'subscriber') return 'primary'
            if (role === 'author') return 'warning'
            if (role === 'maintainer') return 'success'
            if (role === 'editor') return 'info'
            if (role === 'admin') return 'danger'
            return 'primary'
        },
        roleIcon(role) {
            if (role === 'subscriber') return 'UserIcon'
            if (role === 'author') return 'SettingsIcon'
            if (role === 'maintainer') return 'DatabaseIcon'
            if (role === 'editor') return 'Edit2Icon'
            if (role === 'admin') return 'ServerIcon'
            return 'UserIcon'
        }
    }
}
